import {
  createFileRoute,
  useNavigate,
  useRouter,
} from '@tanstack/react-router';
import { useEntity } from '../../../../hooks/useEntities';
import {
  WorkOrder,
  WorkOrderRequest,
  useWorkOrder,
  useWorkOrderUpdate,
} from '../../../../hooks/useWorkOrders';
import { PageHeader } from '../../../../components/PageHeader';
import { ErrorPage } from '../../../../components/ErrorPage';
import { FormattedMessage } from 'react-intl';
import Button from '@/components/core/Button';
import CheckBox from '@/components/core/Checkbox';
import { useEntitySchema } from '../../../../hooks/useEntitySchema';
import {
  EntityPropertyList,
  FormValues,
} from '../../../../components/EntityProp/EntityPropertyList';
import { Entity, EntityProps } from '@/types/entities';
import { ApiEntitySchema } from '@allbin/mobilix-api-client';
import { useForm } from 'react-hook-form';
import { useCallback, useMemo } from 'react';
import { filterProperties } from '@/utils/entity_changeset';
import { EntityGalleryMap } from '@/components/EntityProp/EntityGalleryMap';
import { useImagePicker } from '@/hooks/useImagePicker';
import { useShowAttributes } from '@/hooks/useUserConfig';
import { useEffect } from 'react';

export const Route = createFileRoute(
  '/_layout/workorders/$workorderId/entity/$entityId',
)({
  component: WorkOrderEntity,
});

function WorkOrderEntity() {
  const { workorderId, entityId } = Route.useParams();

  const workorder = useWorkOrder(workorderId);
  const entity = useEntity(entityId);
  const entitySchema = useEntitySchema();

  const { toggleAttributes, showEditAttributes } = useShowAttributes();

  const entityWithChangeset = useMemo<Entity | undefined>(() => {
    if (!entity.data || !workorder.data) {
      return undefined;
    }

    const entityChangeset = workorder.data.entity_changesets[entity.data.id];

    return {
      ...entity.data,
      changeset_head:
        entityChangeset?.prev_changeset_id || entity.data.changeset_head,
      properties: entityChangeset?.properties || entity.data.properties,
    } as Entity;
  }, [entity, workorder.data]);

  if (workorder.isLoading || entity.isLoading) {
    return <div className="mt-16 flex w-full justify-center">Loading...</div>;
  }

  if (workorder.isError) {
    return <ErrorPage error={workorder.error.message} />;
  }

  if (entity.isError) {
    return <ErrorPage error={entity.error.message} />;
  }

  if (!entityWithChangeset || !entitySchema.data || !workorder.data) {
    return (
      <ErrorPage
        error={
          <FormattedMessage defaultMessage="Kan inte hitta arbetsordern eller hållplatsen" />
        }
      />
    );
  }

  return (
    <>
      <PageHeader
        title={entityWithChangeset.full_name}
        subtitle={`#${entityWithChangeset.entity_group}-${entityWithChangeset.stop_letter}`}
        action={
          <CheckBox
            id="edit-attributes"
            label="Endast redigeringsfält"
            checked={showEditAttributes}
            onClick={() => toggleAttributes()}
          />
        }
      />
      {entity.data && entitySchema.data && (
        <EntityForm
          workorder={workorder.data}
          entity={entityWithChangeset}
          schema={entitySchema.data}
        />
      )}
    </>
  );
}

interface EntityFormProps {
  workorder: WorkOrder;
  entity: Entity;
  schema: ApiEntitySchema;
}

function toFormName(key: string) {
  return key.replace('.', '#');
}
function fromFormName(key: string) {
  return key.replace('#', '.');
}

function EntityForm({ workorder, entity, schema }: EntityFormProps) {
  const { history } = useRouter();
  const navigate = useNavigate();
  const update = useWorkOrderUpdate();
  const { resetImages } = useImagePicker();

  useEffect(() => {
    resetImages();
  }, [resetImages]);

  const defaultValues = useMemo<FormValues>(() => {
    // convert . to # in keys to make it valid for react-hook-form
    return schema.definition.properties.reduce((acc, prop) => {
      acc[toFormName(prop.key)] = entity.properties[prop.key] ?? null;
      return acc;
    }, {} as FormValues);
  }, [entity, schema]);

  const { control, handleSubmit, formState, setValue } = useForm<FormValues>({
    defaultValues,
  });

  const onSubmit = useCallback(
    async (formData: FormValues) => {
      if (!workorder || !entity || !schema) return;
      // Replace # with . in keys to turn it back into a proper changeset
      const data = Object.keys(formData).reduce((acc, key) => {
        const id = fromFormName(key);
        const value = formData[key];
        if (value !== null) {
          acc[id] = value;
        }
        return acc;
      }, {} as EntityProps);

      const request: WorkOrderRequest = {
        ...workorder,
        entity_changesets: {
          ...workorder?.entity_changesets,
          [entity.id]: {
            entity_id: entity.id,
            prev_changeset_id: entity.changeset_head,
            properties: filterProperties(data, schema),
          },
        },
      };

      await update.mutateAsync(request);

      navigate({
        to: '/workorders/$workorderId',
        params: { workorderId: workorder.id },
      });
    },
    [entity, navigate, schema, update, workorder],
  );

  return (
    <form className="flex w-full flex-col items-center">
      <div className="flex w-full flex-col overflow-auto md:max-w-screen-lg">
        <EntityGalleryMap
          entityId={entity.id}
          properties={entity.properties}
          schema={schema}
          control={control}
        />
        <EntityPropertyList
          edit
          entityId={entity.id}
          properties={entity.properties}
          schema={schema}
          control={control}
          setValue={setValue}
        />
      </div>
      <div className="flex justify-end gap-4 py-4">
        <Button
          onClick={() => {
            history.back();
          }}
        >
          <FormattedMessage defaultMessage="Avbryt" />
        </Button>
        <Button
          variant="filled"
          onClick={handleSubmit(onSubmit)}
          disabled={!formState.isValid || formState.isSubmitting}
        >
          <FormattedMessage defaultMessage="Spara" />
        </Button>
      </div>
    </form>
  );
}
