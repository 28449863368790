import {
  ApiEntitySchema,
  ApiEntitySchemaGroup,
} from '@allbin/mobilix-api-client';
import { EntityPropValue, EntityProps } from '../../types/entities';
import { EntityProperty, EntityPropertyValue } from './EntityProp';
import { useMemo, useEffect } from 'react';
import { IconWhiteFlag, IconPage } from '@allbin/icons';
import { cn } from '../../utils/classnames';
import { useNavigate } from '@tanstack/react-router';
import { EntityPropertyForm } from './EntityPropertyForm';
import { Control, UseFormSetValue } from 'react-hook-form';
import { useShowAttributes } from '@/hooks/useUserConfig';
import { useErrorReports, ErrorReport } from '@/hooks/useErrorReport';
import IconButton from '../core/IconButton';
import { useImagesFormStore } from '@/hooks/useImagesFormStore';

export type FormValues = Record<string, EntityPropValue | null>;

interface EntityPropertyListProps {
  edit?: boolean;
  entityId: string;
  properties: EntityProps;
  schema: ApiEntitySchema;
  control?: Control<FormValues>;
  setValue?: UseFormSetValue<FormValues>;
}

interface GroupRow {
  type: 'group';
  id: number;
  name: string;
  properties: EntityProperty[];
}

export interface ImageRowProps {
  name: string;
  propertyKey: string;
  imageId: string | null;
}

type PropertyListRow = EntityProperty | GroupRow;

export function EntityPropertyList({
  entityId,
  properties,
  schema,
  control,
  setValue,
}: EntityPropertyListProps) {
  const errorReports = useErrorReports(entityId);
  const setImages = useImagesFormStore((state) => state.setImages);

  useEffect(() => {
    setImages(schema, properties);
  }, [schema, properties, setImages, setValue]);

  const rows = useMemo<PropertyListRow[]>(() => {
    let group: ApiEntitySchemaGroup | undefined = undefined;
    const rows: PropertyListRow[] = [];

    for (const property of schema.definition.properties) {
      // If the group is not defined, add the property directly
      if (!property.group_id) {
        rows.push({
          ...property,
          entityId,
          value: properties[property.key] ?? null,
          extras: schema.extras?.[property.key],
        });
        continue;
      }

      // If the group is defined, ensure that the group exists
      if (property.group_id && property.group_id !== group?.id) {
        group = schema.definition.groups.find(
          (g) => g.id === property.group_id,
        );
      }

      if (group === undefined) {
        console.error(`Group with id ${property.group_id} not found in schema`);
        continue;
      }

      // If the group doesn't exist in rows, add it
      let groupIndex = rows.findIndex(
        (row) => row.type === 'group' && row.id === group!.id,
      );

      if (groupIndex === -1) {
        rows.push({
          type: 'group',
          id: group.id,
          name: group.name,
          properties: [],
        });
      }
      groupIndex = rows.length - 1;

      // Add the property to the group
      (rows[groupIndex] as GroupRow).properties.push({
        ...property,
        entityId,
        value: properties[property.key] || null,
        extras: schema.extras?.[property.key],
      });
    }

    return rows;
  }, [
    entityId,
    properties,
    schema.definition.groups,
    schema.definition.properties,
    schema.extras,
  ]);

  const { showEditAttributes } = useShowAttributes((state) => ({
    showEditAttributes: state.showEditAttributes,
  }));

  const modifiableRows: PropertyListRow[] = useMemo(() => {
    if (!showEditAttributes) {
      return rows;
    }
    return rows.filter(
      (row) =>
        (row.type === 'group' &&
          row.properties.some((prop) => prop.modifiable)) ||
        ('modifiable' in row && row.modifiable),
    );
  }, [rows, showEditAttributes]);

  useEffect(() => {
    const propertyItems = document.querySelectorAll('[data-property-item]');
    propertyItems.forEach((item, index) => {
      if (index % 2 !== 0) {
        item.classList.add('bg-background-200');
      }
    });
  }, [modifiableRows]);

  return (
    <>
      <ul className="flex flex-col">
        {modifiableRows.map((row) =>
          row.type === 'group' ? (
            <EntityListGroup
              key={row.id}
              row={row}
              errorReports={errorReports.data}
              control={control}
              setValue={setValue}
            />
          ) : (
            <EntityListProperty
              key={row.key}
              property={row}
              control={control}
              errorReports={errorReports.data}
              setValue={setValue}
            />
          ),
        )}
      </ul>
    </>
  );
}

function EntityListGroup({
  row,
  control,
  errorReports,
  setValue,
}: {
  row: GroupRow;
  errorReports?: ErrorReport[];
  control?: Control<FormValues>;
  setValue?: UseFormSetValue<FormValues>;
}) {
  return (
    <li key={row.name} className="min-h-14 items-center text-text-300">
      <div data-property-item>
        <h4 className="py-4 pl-4 text-[18px] font-bold text-text-700">
          {row.name}
        </h4>
      </div>
      <ul className="border-l-8 border-primary-500 text-text-950">
        {row.properties.map((prop) => (
          <EntityListProperty
            key={prop.key}
            property={prop}
            errorReports={errorReports}
            control={control}
            setValue={setValue}
          />
        ))}
      </ul>
    </li>
  );
}

interface EntityListPropertyProps {
  property: EntityProperty;
  control?: Control<FormValues>;
  errorReports?: ErrorReport[];
  images?: ImageRowProps[];
  setValue?: UseFormSetValue<FormValues>;
}

function EntityListProperty({
  control,
  property,
  errorReports,
  setValue,
}: EntityListPropertyProps) {
  const navigate = useNavigate();

  const propertyErrorReport = useMemo(() => {
    if (!errorReports) {
      return null;
    }
    return errorReports.filter(
      (report) => report.property_key === property.key,
    );
  }, [errorReports, property.key]);

  if (property.extras?.hidden) {
    return null;
  }

  return (
    <li
      id={property.key}
      key={property.key}
      data-property-item
      className={cn(
        'grid min-h-14 scroll-mt-8 grid-cols-1 items-center border-b border-y-background-300 px-2 first:border-t sm:grid-cols-2',
      )}
    >
      <div className="flex items-center gap-2 pt-2 font-medium sm:justify-start sm:pt-0">
        <span>{property.name}</span>
        {property.extras?.support_urls?.['default'] && (
          <IconButton
            icon={<IconWhiteFlag />}
            className="ml-4"
            onClick={() =>
              navigate({
                to: '/entities/$entityId/error_report',
                params: { entityId: property.entityId },
                search: { propertyId: property.key },
              })
            }
            color="yellow"
          />
        )}
        {propertyErrorReport && propertyErrorReport.length > 0 && (
          <span className="flex items-center gap-1 text-yellow-500">
            {propertyErrorReport.length}
            <IconPage className="size-4" />
          </span>
        )}
      </div>
      <span className="flex flex-1 text-wrap py-2 text-left sm:justify-end">
        {control && property.modifiable ? (
          <EntityPropertyForm
            property={property}
            control={control}
            setValue={setValue}
          />
        ) : (
          <EntityPropertyValue property={property} />
        )}
      </span>
    </li>
  );
}
