import {
  useNavigate,
  ReactNode,
  createFileRoute,
} from '@tanstack/react-router';
import { useWorkOrder } from '../../../../hooks/useWorkOrders';
import { Spinner } from '../../../../components/Spinner';
import { ErrorPage } from '../../../../components/ErrorPage';
import { FormattedMessage } from 'react-intl';
import { dateFormat } from '../../../../utils/dateformat';
import { UserName } from '../../../../components/UserName';
import { StateName } from '../../../../components/StateName';
import { TagName } from '../../../../components/TagName';
import { useEntitiesById } from '../../../../hooks/useEntities';
import { Entity } from '../../../../types/entities';
import { useMemo } from 'react';
import { collator } from '../../../../utils/collator';
import { WorkOrderActionButtons } from '../../../../components/WorkOrderActionButtons';
import { ApiEntityChangeSetRequest } from '@allbin/mobilix-api-client';
import { PageHeader } from '../../../../components/PageHeader';
import IconButton from '@/components/core/IconButton';
import { useEntities } from '../../../../hooks/useEntities';
import { EntityMap } from '@/components/EntityMap';

export const Route = createFileRoute('/_layout/workorders/$workorderId/')({
  component: WorkOrderDetails,
});

function WorkOrderDetails() {
  const { workorderId } = Route.useParams();
  const workorder = useWorkOrder(workorderId);
  const entities = useEntities();

  const workorderEntities = useMemo(() => {
    if (!entities.data || !workorder.data) return;

    return entities.data.filter((entity) =>
      workorder.data?.entities.includes(entity.id),
    );
  }, [entities.data, workorder.data]);

  if (workorder.isLoading || entities.isLoading) {
    return (
      <div className="mt-16 flex w-full justify-center">
        <Spinner />
      </div>
    );
  }

  if (workorder.isError && !workorder.error.message.endsWith('404')) {
    console.error(workorder.error);

    return <ErrorPage error={workorder.error.message} />;
  }

  if (!workorder.data) {
    return (
      <ErrorPage
        title={
          <FormattedMessage defaultMessage="Arbetsordern kunde inte hittas" />
        }
        error={`Arbetsorder #${workorderId.substring(0, 6)} finns inte i systemet, eller så har du inte behörighet att se den.`}
      />
    );
  }

  return (
    <>
      <PageHeader
        title={workorder.data.title}
        subtitle={`#${workorder.data.title_suffix}`}
      />
      <div className="grid grid-cols-1 gap-y-4 md:grid-cols-2 md:gap-x-12">
        <ul className="flex flex-col gap-4">
          <li>
            <Header>
              <FormattedMessage defaultMessage="Slutdatum" />
            </Header>
            <p>
              {!workorder.data.due_at && (
                <span className="italic text-text-600">
                  <FormattedMessage defaultMessage="Inget slutdatum" />
                </span>
              )}

              {workorder.data.due_at?.toFormat(dateFormat)}
            </p>
          </li>
          <li>
            <Header>
              <FormattedMessage defaultMessage="Utförare" />
            </Header>
            <p>
              <UserName id={workorder.data.assignee} />
            </p>
          </li>
          <li>
            <Header>
              <FormattedMessage defaultMessage="Status" />
            </Header>
            <p>
              <StateName state={workorder.data.state} />
            </p>
          </li>
          <li>
            <Header>
              <FormattedMessage defaultMessage="Arbetsinstruktioner" />
            </Header>
            {workorder.data.description ? (
              <p>{workorder.data.description}</p>
            ) : (
              <span className="italic text-text-600">
                <FormattedMessage defaultMessage="Inga arbetsinstruktioner" />
              </span>
            )}
          </li>
          <li>
            <Header>
              <FormattedMessage defaultMessage="Taggar" />
            </Header>
            <div className="flex flex-wrap gap-2">
              {workorder.data.tags.length === 0 && (
                <span className="italic text-text-600">
                  <FormattedMessage defaultMessage="Inga taggar" />
                </span>
              )}
              {workorder.data.tags.map((tag) => (
                <span
                  key={tag}
                  className="rounded-full border border-text-600 px-2 py-1 text-text-600"
                >
                  <TagName id={tag} />
                </span>
              ))}
            </div>
          </li>
          <li className="md:sticky md:top-[115px]">
            <Header>
              <FormattedMessage defaultMessage="Karta" />
            </Header>
            <div className="aspect-square">
              <EntityMap entities={workorderEntities} />
            </div>
          </li>
        </ul>
        <div>
          <Header>
            <FormattedMessage defaultMessage="Hållplatser" />
          </Header>
          <EntityList
            workorderId={workorderId}
            ids={workorder.data.entities}
            changesets={workorder.data.entity_changesets}
          />
        </div>
      </div>
      <div className="grow" />
      <WorkOrderActionButtons workorder={workorder.data} />
    </>
  );
}

function Header({ children }: ReactNode) {
  return <p className="mb-1 text-xs uppercase text-text-700">{children}</p>;
}

interface EntityListProps {
  workorderId: string;
  ids?: string[];
  changesets?: Record<string, ApiEntityChangeSetRequest>;
}

interface EntityGroup {
  id: string;
  name: string;
  entities: Entity[];
}

function EntityList({ workorderId, ids, changesets }: EntityListProps) {
  const entities = useEntitiesById(ids);
  const navigate = useNavigate();
  const groups = useMemo(() => {
    const groupLookup = (entities.data || []).reduce<
      Record<string, EntityGroup>
    >((acc, entity) => {
      const groupId = entity.entity_group;
      const groupName = entity.properties['meta.name'] as string;

      if (!acc[groupId]) {
        acc[groupId] = {
          id: groupId,
          name: groupName,
          entities: [],
        };
      }

      acc[groupId].entities.push(entity);

      return acc;
    }, {});

    // Sort entities by name
    const groups = Object.values(groupLookup).sort((a, b) =>
      collator.compare(a.name, b.name),
    );
    groups.forEach((group) => {
      group.entities.sort((a, b) => {
        return collator.compare(
          a.properties['meta.name'] as string,
          b.properties['meta.name'] as string,
        );
      });
    });

    return groups;
  }, [entities.data]);

  if (entities.isLoading) {
    // Return skeleton loading
    return (
      <div className="flex flex-col gap-4">
        {ids?.map((id) => (
          <div key={id} className="h-6 animate-pulse rounded bg-gray-200" />
        ))}
      </div>
    );
  }

  if (!entities.data) {
    return null;
  }

  return (
    <ul className="flex flex-col ">
      {groups.map((group) => (
        <li
          key={group.id}
          className="mb-2 flex flex-row flex-wrap items-center justify-between gap-2 rounded bg-background-100 p-4"
        >
          <div className="flex flex-row items-center">
            <span className="w-20 text-xs text-text-600">#{group.id}</span>
            <span>{group.name}</span>
          </div>
          <ul className="ml-auto flex flex-row gap-4">
            {group.entities.map((entity) => (
              <IconButton
                key={entity.id}
                variant="filled"
                color={changesets?.[entity.id] ? 'green' : 'entity'}
                icon={entity.stop_letter}
                round
                className="!font-medium"
                onClick={() =>
                  navigate({
                    to: '/workorders/$workorderId/entity/$entityId',
                    params: { workorderId, entityId: entity.id },
                  })
                }
              />
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );
}
