import { UseControllerProps, useController } from 'react-hook-form';
import { FormValues } from '../EntityPropertyList';
import { Dropdown, OptionsProps } from '@/components/core/Dropdown';
import { useMemo } from 'react';
export function EnumInput({
  options,
  ...props
}: UseControllerProps<FormValues> & { options: string[] }) {
  const { field } = useController(props);

  const setOptions: OptionsProps[] = useMemo(() => {
    const optionsArr = options.map((option, index) => ({
      id: index.toString(),
      label: option,
    }));
    optionsArr.unshift({ id: 'null', label: 'Inget värde' });
    return optionsArr;
  }, [options]);

  if (setOptions.length === 0) {
    return <i>Inga alternativ hittades</i>;
  }

  return (
    <div className="flex justify-end">
      <Dropdown
        className="w-48"
        id={props.name}
        placeholder="Inget värde"
        options={setOptions}
        value={{
          id:
            setOptions.find((option) => option.label === field.value)?.id ||
            (setOptions[0].id as string),
          label: field.value || setOptions[0].label,
        }}
        onValueChange={(value) =>
          field.onChange(
            value.id === 'null'
              ? null
              : setOptions.find((option) => option.id === value.id)?.label,
          )
        }
      />
    </div>
  );
}
