import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useEntities, useEntity } from '../../../../hooks/useEntities';
import { Spinner } from '../../../../components/Spinner';
import { ErrorPage } from '../../../../components/ErrorPage';
import { FormattedMessage } from 'react-intl';
import { useEntitySchema } from '../../../../hooks/useEntitySchema';
import { useCallback, useMemo } from 'react';
import { IconClockRotateRight, IconEyeSolid } from '@allbin/icons';
import Button from '@/components/core/Button';
import { PageHeader } from '../../../../components/PageHeader';
import { collator } from '../../../../utils/collator';
import { EntityPropertyList } from '../../../../components/EntityProp/EntityPropertyList';
import { EntityGalleryMap } from '../../../../components/EntityProp/EntityGalleryMap';
import { useCreateCheckIn } from '@/hooks/useCheckIn';
import { toast } from '@/hooks/useToast';
import IconButton from '@/components/core/IconButton';
import { cn } from '@/utils/classnames';

export const Route = createFileRoute('/_layout/entities/$entityId/')({
  component: EntityDetails,
});

function EntityDetails() {
  const { entityId } = Route.useParams();
  const navigate = useNavigate();

  const schema = useEntitySchema();
  const entity = useEntity(entityId);
  const entities = useEntities();
  const createCheckIn = useCreateCheckIn();

  const stops = useMemo(() => {
    if (!entity.data || !entities.data) {
      return [];
    }

    return entities.data
      .filter((e) => e.entity_group === entity.data.entity_group)
      .map((entity) => ({ id: entity.id, letter: entity.stop_letter }))
      .sort((a, b) => collator.compare(a.letter, b.letter));
  }, [entities.data, entity.data]);

  const onCreateCheckIn = useCallback(async () => {
    await createCheckIn.mutateAsync(entityId);
    toast.success({
      title: 'Tillsyn utförd',
    });
  }, [createCheckIn, entityId]);

  const pageHeaderAction = useMemo(() => {
    if (!entity.data?.id) {
      return null;
    }

    if (stops.length < 2) {
      return null;
    }

    return (
      <div className="flex h-full flex-wrap items-center justify-end gap-3">
        {stops.map((stop) => (
          <IconButton
            key={stop.id}
            variant="filled"
            color="entity"
            icon={stop.letter}
            round
            className={cn(
              '!font-medium',
              stop.id === entityId && 'border-4 border-primary-500',
            )}
            onClick={() =>
              navigate({
                to: '/entities/$entityId',
                params: { entityId: stop.id },
              })
            }
          />
        ))}
      </div>
    );
  }, [entity?.data?.id, navigate, stops, entityId]);

  if (schema.isLoading || entity.isLoading || entities.isLoading) {
    return (
      <div className="mt-16 flex w-full justify-center">
        <Spinner />
      </div>
    );
  }

  if (entity.isError && !entity.error.message.endsWith('404')) {
    console.error(entity.error);

    return <ErrorPage error={entity.error.message} />;
  }

  if (!schema.data || !entity.data) {
    return (
      <ErrorPage
        title={
          <FormattedMessage defaultMessage="Hållplats kunde inte hittas" />
        }
        error={`Hållplats #${entityId.substring(0, 6)} finns inte i systemet, eller så har du inte behörighet att se den.`}
      />
    );
  }

  return (
    <>
      <PageHeader
        title={entity.data.full_name}
        subtitle={`#${entity.data.entity_group}-${entity.data.stop_letter}`}
        action={pageHeaderAction}
      />
      <div className="w-full">
        <EntityGalleryMap
          entityId={entityId}
          properties={entity.data.properties}
          schema={schema.data}
        />
        <EntityPropertyList
          entityId={entityId}
          properties={entity.data.properties}
          schema={schema.data}
        />
      </div>
      <div className="sticky bottom-0 mt-8 flex w-full justify-center gap-4 border-t border-t-background-200 bg-background-50 p-4">
        <Button onClick={onCreateCheckIn} startIcon={<IconEyeSolid />}>
          <FormattedMessage defaultMessage="Utför tillsyn" />
        </Button>
        <Button
          startIcon={<IconClockRotateRight />}
          onClick={() =>
            navigate({
              to: '/entities/$entityId/events',
              params: { entityId },
            })
          }
        >
          <FormattedMessage defaultMessage="Händelser" />
        </Button>
      </div>
    </>
  );
}
